import { post, put } from "_common/service/ApiService";

let routeSaveRating = process.env.REACT_APP_API_URL + "/api/notation/";
export const saveRating = async (notation = null, commentaire = null) => {
    const formData = new FormData();
    notation && formData.append("note", notation);
    commentaire && formData.append("commentaire", commentaire);
    !notation && !commentaire && formData.append("isWithoutNotationAndComment", true);

    return await post(routeSaveRating, {
        body: formData
    });
};

let routeEditionRating = process.env.REACT_APP_API_URL + "/api/notation/{id}";
export const saveEditionRating = async (id, notation = null, commentaire = null) => {
    const url = routeEditionRating.replace("{id}", id)
    return await put(url, {
        body: JSON.stringify({
        note: notation && Number(notation),
        commentaire: commentaire !== null && commentaire
        })
    });
};
