import Style from "./AuthentificationResetForgottenPassword.module.css";
import LogoFDT from "_common/component/icon/logoFDT.svg";
import { sendResetPassword } from "_core/authentification/service/ResetPasswordService";
import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTE_HOME } from "_core/router/routes";

const AuthentificationResetForgottenPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [submitMessageShown, setSubmitMessageShown] = useState(false);

    const submit = async (e) => {
        if (email.length > 0) {
            e.preventDefault();
            try {
                await sendResetPassword(email);
            } catch (err) {
                setError("Nous n'avons pas trouvé votre e-mail dans notre base de donnée")
            }
            setEmail('');
            setSubmitMessageShown(true)
        }
    }

    return <div className={Style.BlueBackground}>
        <header className="sr-only">
            <h1 className="sr-only">Réinitialiser mon mot de passe</h1>
        </header>
        <div className={Style.WhiteContainer}>
            <Link to={ROUTE_HOME} className={Style.Logo}>
                <img src={LogoFDT} alt="Retour à la page de connexion" />
            </Link>
            {submitMessageShown && <h1 className={Style.LabelSubmitMessage}>Un e-mail de réinitialisation du mot de passe vient de vous être envoyé.</h1>}
            {!submitMessageShown && <div className={Style.FormContainer}>
                <h1 className={Style.LabelTitre}>Entrez votre adresse e-mail afin de réinitialiser votre mot de passe</h1>
                <form>
                    {error && <label className={Style.Error}>{error}</label>}
                    <label htmlFor="a11y-email" className={Style.LabelEmail} lang="en">E-mail</label>
                    <input id="a11y-email"
                        autoComplete="email"
                        type="email"
                        className={clsx(Style.InputMail, "form-control")}
                        value={email}
                        required
                        onChange={e => setEmail(e.target.value)}
                    />
                    <div className={Style.ButtonContainer}>
                        <button type="submit" onClick={submit} className={Style.ButtonSubmit}>Envoyer</button>
                    </div>
                </form>
            </div>}
        </div>
    </div>
}

export default AuthentificationResetForgottenPassword;