import React from "react";
import PropTypes from "prop-types";

const Ressource = ({ color = "#1D1D1F" }) => {
    return (
        <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
            <path d="M17.8816 8.38785C17.793 8.38785 17.7047 8.3522 17.6403 8.28122C17.5186 8.14777 17.5277 7.94106 17.6609 7.81906C18.8848 6.70078 20.3658 5.88243 22.062 5.38625C22.2357 5.33556 22.4169 5.43532 22.4676 5.60834C22.5183 5.78169 22.4189 5.96322 22.2452 6.01424C20.6454 6.48196 19.2518 7.25158 18.1021 8.30183C18.0393 8.35972 17.9605 8.38785 17.8816 8.38785Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M17.8816 11.6705C17.793 11.6705 17.7047 11.6349 17.6403 11.5639C17.5186 11.4305 17.5277 11.2238 17.6609 11.1018C18.8848 9.98413 20.3655 9.16578 22.0614 8.6696C22.235 8.61858 22.4162 8.71833 22.4669 8.89169C22.5176 9.06504 22.4182 9.24657 22.2445 9.29759C20.6451 9.76531 19.2514 10.5346 18.1021 11.5845C18.0393 11.6424 17.9605 11.6705 17.8816 11.6705Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M17.8816 14.9561C17.793 14.9561 17.7047 14.9205 17.6403 14.8498C17.5186 14.7164 17.5277 14.5093 17.6609 14.3877C18.8851 13.2697 20.3658 12.4517 22.0614 11.9559C22.235 11.9045 22.4162 12.0046 22.4669 12.1779C22.5176 12.3513 22.4182 12.5328 22.2445 12.5838C20.6454 13.0512 19.2518 13.8205 18.1021 14.8708C18.0393 14.9277 17.9605 14.9561 17.8816 14.9561Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M29.1728 7.24394H28.1439V6.85472C28.1439 6.70394 28.0408 6.57245 27.8936 6.5368C26.7842 6.26762 25.6607 6.07301 24.548 5.95035V1.32678C24.548 1.23258 24.5074 1.14263 24.4361 1.08082C24.3655 1.01867 24.2726 0.99087 24.1774 1.00264C20.3254 1.51747 17.4298 3.36349 15.5612 6.48349C11.5718 5.55295 7.52978 5.56898 3.54238 6.5368C3.39584 6.57245 3.29249 6.70361 3.29249 6.85472V7.24394H2.26448C1.56715 7.24394 1 7.81142 1 8.50843V25.7209C1 26.4179 1.56715 26.9851 2.26448 26.9851H29.1728C29.8698 26.9851 30.437 26.4179 30.437 25.7209V8.50843C30.437 7.81142 29.8698 7.24394 29.1728 7.24394ZM27.4897 7.11311V24.1804C23.8114 23.3479 20.0889 23.3237 16.4073 24.1035C18.1739 21.4368 20.8121 19.8508 24.2647 19.3893C24.4269 19.3677 24.5483 19.2291 24.5483 19.0652V6.60843C25.5263 6.71898 26.5124 6.88612 27.4897 7.11311ZM23.8941 1.70554V18.7819C20.4994 19.2997 17.8651 20.8736 16.0456 23.468V6.94238C17.7447 4.02681 20.3839 2.26647 23.8941 1.70554ZM3.9473 7.11311C7.74565 6.23033 11.5931 6.23033 15.3914 7.11311V24.1804C13.4918 23.7506 11.5806 23.535 9.6692 23.535C7.75775 23.535 5.84664 23.7503 3.94697 24.1804L3.9473 7.11311ZM1.65416 25.7209V8.50843C1.65416 8.17186 1.92792 7.8981 2.26448 7.8981H3.29314V24.5931C3.29314 24.6935 3.33926 24.7884 3.41809 24.8502C3.49691 24.9117 3.59961 24.9339 3.69741 24.9111C7.57688 23.9697 11.51 23.9527 15.3914 24.8541V26.3309H2.26448C1.92792 26.3312 1.65416 26.0575 1.65416 25.7209ZM29.7828 25.7209C29.7828 26.0571 29.5091 26.3309 29.1728 26.3309H16.0456V24.8541C19.927 23.9527 23.8595 23.9694 27.7399 24.9111C27.7651 24.9176 27.7909 24.9202 27.8168 24.9202C27.8894 24.9202 27.9607 24.896 28.0189 24.8502C28.0977 24.7884 28.1439 24.6935 28.1439 24.5931V7.8981H29.1728C29.5091 7.8981 29.7828 8.17186 29.7828 8.50843V25.7209Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M17.8816 18.242C17.793 18.242 17.7047 18.2064 17.6403 18.1357C17.5186 18.0023 17.5277 17.7952 17.6609 17.6736C18.8848 16.5556 20.3651 15.7373 22.061 15.2417C22.2347 15.191 22.4159 15.2905 22.4666 15.4642C22.5173 15.6378 22.4179 15.819 22.2442 15.8697C20.6451 16.3371 19.2514 17.1061 18.1021 18.1563C18.0393 18.2136 17.9605 18.242 17.8816 18.242Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M5.70546 10.7045C5.54748 10.7045 5.40847 10.5897 5.38296 10.4288C5.35483 10.2505 5.47617 10.0827 5.65476 10.0543C8.30409 9.63265 10.991 9.63036 13.641 10.0474C13.8196 10.0755 13.9413 10.243 13.9135 10.4212C13.8853 10.5998 13.7185 10.7215 13.5396 10.6937C10.9577 10.2878 8.33974 10.2901 5.75779 10.7006C5.7398 10.7032 5.72246 10.7045 5.70546 10.7045Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M5.70546 13.9904C5.54748 13.9904 5.40847 13.8756 5.38296 13.7146C5.35483 13.5364 5.47617 13.3686 5.65476 13.3401C8.30474 12.9189 10.9914 12.9166 13.641 13.3333C13.8196 13.3614 13.9413 13.5289 13.9135 13.7071C13.8853 13.8857 13.7185 14.0084 13.5396 13.9796C10.9577 13.5737 8.33974 13.5756 5.75779 13.9865C5.7398 13.9891 5.72246 13.9904 5.70546 13.9904Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M5.70546 17.2746C5.54748 17.2746 5.40847 17.1598 5.38296 16.9988C5.35483 16.8202 5.47617 16.6525 5.65476 16.6243C8.3054 16.2034 10.9927 16.2004 13.641 16.6175C13.8196 16.6456 13.9413 16.8134 13.9135 16.9916C13.8853 17.1702 13.7169 17.2909 13.5396 17.2641C10.9587 16.8575 8.34072 16.8601 5.75779 17.2706C5.74013 17.2732 5.72279 17.2746 5.70546 17.2746Z" fill={color} stroke={color} strokeWidth="0.5"/>
            <path d="M5.70578 20.5596C5.5478 20.5596 5.4088 20.4448 5.38296 20.2839C5.35483 20.1053 5.47617 19.9378 5.65476 19.9094C8.30703 19.4878 10.9969 19.4861 13.6502 19.9035C13.8284 19.9319 13.9504 20.0994 13.9223 20.2776C13.8945 20.4562 13.7267 20.5769 13.5481 20.5501C10.9626 20.1426 8.34105 20.1442 5.75746 20.5553C5.74013 20.5583 5.72279 20.5596 5.70578 20.5596Z" fill={color} stroke={color} strokeWidth="0.5"/>
        </svg>

    );
};
Ressource.propTypes = {
    color: PropTypes.string,
};
export default Ressource;
