import React from "react";

import FooterDroite from "_common/component/icon/FooterDroite";
import FooterGauche from "_common/component/icon/FooterGauche";
import Style from "./AppFooterDesktop.module.css";
import ElementsJuridiques from "_core/app/elements-juridiques/ElementsJuridiques";
import ReseauxSociaux from "_core/app/reseaux-sociaux/ReseauxSociaux";

const AppFooter = () => {
  return (
    <footer role="contentinfo" aria-label="Informations complémentaires" className={Style.FooterDesktop}>
      <FooterGauche />
      <FooterDroite />
      <ElementsJuridiques />
      <ReseauxSociaux/>
    </footer>
  );
};
export default AppFooter;
