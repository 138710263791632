import { post } from "_common/service/ApiService";

let routeAdhesion = process.env.REACT_APP_API_URL + "/api/adhesion";
export const adhesionPaiement = async (checked) => {
    return await post(routeAdhesion, {
        body: JSON.stringify({
            minima: checked
        })
    });
}

let routeStatutPaiement = process.env.REACT_APP_API_URL + "/api/change_statut_paiement";
export const changeStatutPaiement = async (checkoutId) => {
    return await post(routeStatutPaiement, {
        body: JSON.stringify({
            checkoutId: checkoutId
        })
    });
}