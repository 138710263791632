import Style from "./AuthentificationInscription.module.css";
import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { ROUTE_HOME } from "_core/router/routes";
import vagueLogin from "_common/component/icon/vagueLoginDesktop.svg";
import clsx from "clsx";
import eye from "_common/component/icon/eye.svg";
import { saveInscription } from "_core/authentification/service/AuthService";
import { getVilles } from "_core/authentification/service/AuthServiceApi";
import LogoFdt from "_common/component/icon/logoFDT.svg";
import { debounce } from "_common/service/FunUtil";
import useMedia from "_common/hook/useMedia";
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import information from "_common/component/icon/information.svg";

const AuthentificationInscription = () => {
    const isMobile = useMedia(["(max-width: 425px)", "(min-width: 426px)"], [true, false], true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [errorServeur, setErrorServeur] = useState('');
    const [messageAlerteVille, setMessageAlerteVille] = useState('');

    const togglePasswordVisiblity = () => {
        setIsPasswordShown(!isPasswordShown);
    };

    const validationInscriptionSchema = Yup.object().shape({
        nom: Yup.string().min(2, 'Le nom doit comporter au minimum 2 caractères.').max(45, 'Le nom doit comporter au maximum 45 caractères.').required('Le champ "Nom" est obligatoire.'),
        prenom: Yup.string().min(2, 'Le prénom doit comporter au minimum 2 caractères.').max(45, 'Le prénom doit comporter au maximum 45 caractères.').required('Le champ "Prénom" est obligatoire.'),
        codePostal: Yup.string()
            .matches(
            /[0-9]{5}/,
            "Le code postal doit contenir 5 chiffres."
            )
            .required('Le champ "Code Postal" est obligatoire.'),
        ville: Yup.string().when('codePostal', ([codePostal], validationInscriptionSchema) => {
            return codePostal && codePostal.trim().length === 5 ? validationInscriptionSchema.required('Le champ "Ville" est obligatoire. Veuillez sélectionner une ville dans la liste.') : validationInscriptionSchema.required('Le champ "Ville" est obligatoire. Veuillez renseigner un Code Postal valide pour pouvoir sélectionner une ville dans la liste.');
        }),
        email: Yup.string().email('L\'e-mail indiqué est invalide.').required('Le champ "E-mail" est obligatoire.'),
        confEmail: Yup.string()
            .email('L\'e-mail indiqué est invalide.')
            .required('Le champ "Confirmation e-mail" est obligatoire.')
            .oneOf([Yup.ref('email'), null], 'Les adresses email ne sont pas identiques.'),
        password: Yup.string()
            .required('Le champ "Mot de passe" est obligatoire.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W)(?=.{8,})/,
                "Le mot de passe doit contenir au minimum 8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial."
            ),
        checked: Yup.string().required('Veuillez accepter la charte et les CGU.')
    });

    const [visibleOptionsArray, setVisibleOptionsArray] = useState([]);

    const loadVilles = useCallback(debounce(500, (code) => {
        const load = async () => {
            const villes = await getVilles(code);
            setVisibleOptionsArray(villes);
        }
        load()
    }), []);

    return (
    <>
        <div className={Style.Content}>
            <header className="sr-only">
                <h1 className="sr-only">Inscription à Femmes des Territoires</h1>
            </header>
            <aside className={Style.LeftSideLogin}>
                <img className={Style.LogoFdt} src={LogoFdt} alt="" aria-hidden="true" />
                <div>
                    <h2 className={Style.Text}>
                        <span className={clsx(Style.BoldText, Style.Title)}>Découvrez l’univers Femmes des Territoires</span><br />
                        grâce à<br />
                        <span className={Style.BlueText}>2 mois offerts et sans engagement !</span>
                    </h2>
                </div>
                <div>
                    <p className={clsx(Style.Computer, Style.Text, Style.BoldText)}><span className={Style.HighlightText}>Aujourd&rsquo;hui</span> je m&rsquo;inscris gratuitement et je découvre le réseau.</p>
                </div>
                <div>
                    <p className={clsx(Style.BoldText, Style.Text)}>Je profite <span className={Style.HighlightText}>immédiatement</span> de tous les avantages :</p>
                    <ul className={Style.AvantagesList}>
                        <li className={clsx(Style.Avantage, Style.WomenGroup)}>Je rejoins une communauté d&rsquo;entrepreneures</li>
                        <li className={clsx(Style.Avantage, Style.BusinessWoman)}>Je participe à des ateliers en ligne et/ou en présentiel</li>
                        <li className={clsx(Style.Avantage, Style.France)}>J&rsquo;intègre une antenne près de chez moi</li>
                        <li className={clsx(Style.Avantage, Style.WomanOnPhone)}>Je demande et je donne des coups de pouce</li>
                    </ul>
                </div>
                <p className={clsx(Style.BoldText, Style.Text)}>
                    <span className={Style.HighlightText}>Dans 2 mois</span> je peux poursuivre mon adhésion pour 60€ par an - <br />
                    <span className={Style.MinimaSociaux}>30€ si vous bénéficiez des minima sociaux.
                        <img src={information} alt="" aria-hidden="true" className={Style.InformationImage}/>
                        <span className={Style.InfoBulle}>Il faut justifier bénéficier soit du RSA, de l&rsquo;AAH, ASS, ASPA, ADA, ASV, ASPA, RSO, AV ou ATA.</span>
                    </span>
                </p>
            </aside>

            <main className={Style.RightSideLogin}>
                {isSubmitted === false ?
                <>
                    <h2 className={Style.TitleSecondary}>Je m&rsquo;inscris à{isMobile && <br />}<span className={Style.BlueTitle}>Femmes des Territoires !</span></h2>
                    <p className={Style.Centrer}>Vous avez déjà un compte ? <Link className={Style.InscrivezVous} to={ROUTE_HOME} target="_blank" rel="noopener">Connectez-vous !</Link></p>
                    <span className={clsx("special-italic-text", Style.RequiredText)} aria-hidden="true"> * champs obligatoires</span>
                    <Formik
                        initialValues={{
                            nom: "",
                            prenom: "",
                            codePostal: "",
                            ville: "",
                            email: "",
                            confEmail: "",
                            password: "",
                            checked: ""
                        }}
                        validationSchema={validationInscriptionSchema}
                        onSubmit={async (values) => {
                            try {
                                await saveInscription(values);
                                setIsSubmitted(true);
                            } catch (err) {
                                setErrorServeur("Une erreur est survenue.");
                            }
                        }}
                    >
                        {({ handleChange, touched, values, errors, setFieldValue, isSubmitting }) => (
                            <Form className={Style.FormContainer} noValidate method="post">
                                <div id="zone-alerte" role="alert" aria-live="assertive" className="sr-only">{messageAlerteVille}</div>
                                {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 || errorServeur) &&
                                <div role="alert">
                                    <h3 className={clsx("special-italic-text", "red-error", Style.ErrorsContainerTitre)}>Ce formulaire comprend {Object.keys(errors).length + (errorServeur ? 1 : 0)} erreur{Object.keys(errors).length + (errorServeur ? 1 : 0) > 1 ? 's' : ''} :</h3>
                                    <ul className={Style.ErrorsContainer}>
                                        {errors.prenom && touched.prenom && <li><a className={clsx("special-italic-text", "red-error")} href="#a11y-prenom" id="prenom_error">{errors.prenom}</a></li>}
                                        {errors.nom && touched.nom && <li><a className={clsx("special-italic-text", "red-error")} href="#a11y-nom" id="nom_error">{errors.nom}</a></li>}
                                        {errors.codePostal && touched.codePostal && <li><a className={clsx("special-italic-text", "red-error")} href="#a11y-code-postal" id="code_postal_error">{errors.codePostal}</a></li>}
                                        {errors.ville && touched.ville && <li><a className={clsx("special-italic-text", "red-error")} href="#a11y-ville" id="ville_error">{errors.ville}</a></li>}
                                        {errors.email && touched.email && <li><a className={clsx("special-italic-text", "red-error")} href="#a11y-email" id="email_error">{errors.email}</a></li>}
                                        {errors.confEmail && touched.confEmail && <li><a className={clsx("special-italic-text", "red-error")} href="#a11y-email-confirmation" id="confemail_error">{errors.confEmail}</a></li>}
                                        {errors.password && touched.password && <li><a className={clsx("special-italic-text", "red-error")} href="#a11y-mot-de-passe" id="password_error">{errors.password}</a></li>}
                                        {errors.checked && touched.checked && <li><a className={clsx("special-italic-text", "red-error")} href="#charte" id="charte_error">{errors.checked}</a></li>}
                                        {errorServeur && <li className={clsx("special-italic-text", "red-error")} >{errorServeur}</li>}
                                    </ul>
                                </div>}

                                <div className={Style.NomPrenom}>
                                    <div className={Style.Prenom}>
                                        <label htmlFor="a11y-prenom" className={clsx(Style.Label, "label-input")}>Prénom <span aria-hidden="true">*</span></label>
                                        <input id="a11y-prenom"
                                            autoComplete="given-name"
                                            type="text"
                                            maxLength="45"
                                            title="Le nom doit contenir 45 caractères maximum."
                                            className={errors.prenom && touched.prenom ? clsx("input-field", "red-error-field") : "input-field"}
                                            name="prenom"
                                            value={values.prenom}
                                            required
                                            aria-describedby="prenom_error"
                                            onChange={handleChange}
                                        />
                                        {errors.prenom && touched.prenom && <span className={clsx("special-italic-text", "red-error")}>{errors.prenom}</span>}
                                    </div>
                                    <div className={Style.Nom}>
                                        <label htmlFor="a11y-nom" className={clsx(Style.Label, "label-input")}>Nom <span aria-hidden="true">*</span></label>
                                        <input id="a11y-nom"
                                            autoComplete="family-name"
                                            type="text"
                                            maxLength="45"
                                            title="Le prénom doit contenir 45 caractères maximum."
                                            className={errors.nom && touched.nom ? clsx("input-field", "red-error-field") : "input-field"}
                                            name="nom"
                                            value={values.nom}
                                            required
                                            aria-describedby="nom_error"
                                            onChange={handleChange}
                                        />
                                        {errors.nom && touched.nom && <span className={clsx("special-italic-text", "red-error")} >{errors.nom}</span>}
                                    </div>
                                </div>
                                <div className={Style.PostalCodeContainer}>
                                    <div>
                                        <label htmlFor="a11y-code-postal" className={clsx(Style.Label, "label-input")} aria-label="Vous devez remplir le code postal pour pouvoir remplir la ville dans le prochain champ">Code Postal <span aria-hidden="true">*</span></label>
                                        <input id="a11y-code-postal"
                                            name="codePostal"
                                            autoComplete="none"
                                            type="text"
                                            pattern="[0-9]{5}"
                                            title="Le code postal doit contenir 5 chiffres."
                                            className={errors.codePostal && touched.codePostal ? clsx("input-field", "red-error-field") : "input-field"}
                                            value={values.codePostal}
                                            required
                                            aria-describedby="code_postal_error"
                                            onChange={(e) => {
                                                handleChange(e);
                                                if (e.target.value.trim() && e.target.value.length === 5) {
                                                    loadVilles(e.target.value.trim());
                                                    setFieldValue("codePostal", e.target.value.trim());
                                                    setMessageAlerteVille('La liste des villes que vous pouvez sélectionner a été mise à jour avec votre code postal');
                                                }
                                            }}
                                        />
                                        {errors.codePostal && touched.codePostal && <span className={clsx("special-italic-text", "red-error")} >{errors.codePostal}</span>}
                                    </div>
                                    <label htmlFor="a11y-ville" className={clsx(Style.Label, "label-input")} aria-label="Vous devez remplir le code postal pour pouvoir remplir ce champ">Ville <span aria-hidden="true">*</span></label>
                                    <div className={clsx(Style.CustomSelect, "custom-select")}>
                                        <select id="a11y-ville"
                                            className={errors.ville && touched.ville ? clsx("input-field", "red-error-field") : "input-field"}
                                            name="ville"
                                            value={values.ville}
                                            required
                                            aria-describedby="ville_error"
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled> Sélectionnez votre ville dans la liste</option>
                                            {visibleOptionsArray.map(c => {
                                                return (
                                                    <option key={c.id} value={c.id}>{c.nom}</option>
                                                );
                                            })}
                                        </select>
                                        {errors.ville && touched.ville && <span className={clsx("special-italic-text", "red-error")} >{errors.ville}</span>}
                                    </div>
                                </div>
                                <label htmlFor="a11y-email" className={clsx(Style.Label, "label-input")}>E-mail <span aria-hidden="true">*</span></label>
                                <input id="a11y-email"
                                    autoComplete="email"
                                    type="email"
                                    className={errors.email && touched.email ? clsx("input-field", "red-error-field") : "input-field"}
                                    name="email"
                                    value={values.email}
                                    required
                                    aria-describedby="email_error"
                                    onChange={handleChange}
                                />
                                {errors.email && touched.email && <span className={clsx("special-italic-text", "red-error")} >{errors.email}</span>}

                                <label htmlFor="a11y-email-confirmation" className={clsx(Style.Label, "label-input")}>Confirmation e-mail <span aria-hidden="true">*</span></label>
                                <input id="a11y-email-confirmation"
                                    autoComplete="email"
                                    type="email"
                                    className={errors.confEmail && touched.confEmail ? clsx("input-field", "red-error-field") : "input-field"}
                                    name="confEmail"
                                    value={values.confEmail}
                                    required
                                    aria-describedby="confemail_error"
                                    onChange={handleChange}
                                />
                                {errors.confEmail && touched.confEmail && <span className={clsx("special-italic-text", "red-error")}>{errors.confEmail}</span>}

                                <label htmlFor="a11y-mot-de-passe" className={clsx(Style.Label, "label-input")}>Mot de Passe <span aria-hidden="true">*</span></label>
                                <p className={clsx("special-italic-text", Style.PasswordInstruction)} >Le mot de passe doit contenir au minimum 8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial.</p>
                                <div className={Style.ContainerPassword}>
                                    <input id="a11y-mot-de-passe"
                                        autoComplete="new-password"
                                        type={isPasswordShown ? "text" : "password"}
                                        className={errors.password && touched.password ? clsx("input-field", "red-error-field") : "input-field"}
                                        name="password"
                                        value={values.password}
                                        required
                                        aria-describedby="password_error"
                                        onChange={handleChange}
                                    />
                                    <button type="button" className={`button-icon ${Style.buttonEye}`} onClick={togglePasswordVisiblity}>
                                        <img src={eye} alt="" aria-hidden="true" />
                                        <span className="sr-only">
                                            {isPasswordShown ? "cacher" : "voir"} le mot de passe
                                        </span>
                                    </button>
                                    {errors.password && touched.password && <span className={clsx("special-italic-text", "red-error")} >{errors.password}</span>}
                                </div>

                                <input id="charte"
                                    type="radio"
                                    name="checked"
                                    checked={values.checked}
                                    onChange={handleChange}
                                    className="sr-only"
                                    aria-label="Accepter la charte et les Conditions Générales d'Utilisation"
                                    tabIndex="0"
                                    aria-describedby="charte_error"
                                />
                                <label htmlFor="charte"> J&rsquo;accepte la <a className={Style.Link} href="https://www.femmesdesterritoires.fr/charte/" target="_blank" rel="noopener noreferrer" aria-label="lien vers la page de la charte de Femmes des Territoires">charte</a> et les <a className={Style.Link} href="https://www.femmesdesterritoires.fr/cgv-cgu/" target="_blank" rel="noopener noreferrer" aria-label="lien vers la page des CGU de Femmes des Territoires">Conditions Générales d&rsquo;Utilisation</a></label>
                                {errors.checked && touched.checked && <span className={clsx("special-italic-text", "red-error")} >{errors.checked}</span>}

                                <div className={Style.ButtonContainer}>
                                    <button className={clsx(Style.ButtonConnexion, "button-green", "coloured-button", "button")} type="submit" disabled={isSubmitting}>Je m&rsquo;inscris</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
                : 
                    <div className={Style.ContainerValidationText}>
                        <p className={Style.BlueText}>Vous allez recevoir un e-mail de confirmation d&rsquo;inscription.</p>
                        <p className={clsx(Style.BlueText, Style.ValidationText)}>Cliquez sur le lien qu&rsquo;il contient pour valider votre demande et on se retrouve tout de suite après !</p>
                        <p className={Style.Text}>Si vous n’avez toujours pas reçu de mail après quelques minutes, pensez à vérifier vos spams ou contactez nous à contact@femmesdesterritoires.fr</p>
                    </div>
                }
            </main>
            <img src={vagueLogin} alt="" className={Style.VagueLogin} />
        </div>
    </>
    );
}

export default AuthentificationInscription;