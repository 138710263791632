import { get, post } from "_common/service/ApiService";

let routeCloseBanner = process.env.REACT_APP_API_URL + "/api/banniere/{id}/vue";
export const saveClickOnCloseBanner = async (bannerId) => {
    const formData = new FormData();
    formData.append("bannerId", bannerId);
    const url = routeCloseBanner.replace("{id}", bannerId)
    return await post(url, {
        body: formData
    });
}

export const getBannerDetail = async () => {
    return await get(process.env.REACT_APP_API_URL + "/api/banniere/en-cours");
};