import React from "react";
import PropTypes from "prop-types";

const FooterGauche = ({ width = 348, height = 103 }) => {
  return (
    <svg style={{ width, height }} fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img">
      <path
        d="M32 47C74.5 105.5 110 100.5 179.5 86C124.665 59.7327 91.3948 50.9243 32 47Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M0 103V0C43.2277 69.2156 67.2479 93.8117 109.5 95.5C167.171 93.5285 210.5 74.5 252 78C293.5 81.5 347.5 103 347.5 103H0Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="31.5146"
          y1="47"
          x2="354.254"
          y2="81.1755"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00133931" stopColor="#FFC412" />
          <stop offset="0.75089" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="9" y1="110" x2="347" y2="103" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00AAA5" />
          <stop offset="0.670435" stopColor="#00AAA5" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
FooterGauche.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FooterGauche;
