import React from "react";
import PropTypes from "prop-types";

const Home = ({ color = "#1D1D1F" }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <path
        d="M3.5 9.99658L12.5 2.99658L21.5 9.99658V20.9966C21.5 21.527 21.2893 22.0357 20.9142 22.4108C20.5391 22.7859 20.0304 22.9966 19.5 22.9966H5.5C4.96957 22.9966 4.46086 22.7859 4.08579 22.4108C3.71071 22.0357 3.5 21.527 3.5 20.9966V9.99658Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 22.9966V12.9966H15.5V22.9966"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Home.propTypes = {
  color: PropTypes.string,
};

export default Home;
