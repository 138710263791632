import React, {Suspense, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes, {
    ROUTE_ADHESION_PAIEMENT, 
    ROUTE_CONNEXION,
    ROUTE_FIN_ADHESION, 
    ROUTE_HOME,
    ROUTE_INSCRIPTION,
    ROUTE_NOUVEAU_MDP,
    ROUTE_REINITIALISATION_MDP
} from '_core/router/routes';
import HeaderFooterControl from './HeaderFooterControl';
import { useAuth, CONNECTED } from '_core/authentification/service/AuthService';
import { storageRead } from '_common/service/StorageService';
import ToasterProvider from '_common/component/toaster/ToasterProvider';
import AuthentificationConnexion from '_core/authentification/component/connexion/AuthentificationConnexion';
import Inscription from '_core/authentification/component/inscription/AuthentificationInscription';
import AuthentificationResetForgottenPassword from '_core/authentification/component/mot-de-passe/AuthentificationResetForgottenPassword';
import AuthentificationSetPassword from '_core/authentification/component/mot-de-passe/AuthentificationSetPassword';
import AdhesionEnding from '_core/adhesion/component/AdhesionEnding';
import AdhesionPayement from '_core/adhesion/component/AdhesionPayement';
import AccessibilityGlobalMenu from './accessibility-global-menu/AccessibilityGlobalMenu';
import { initMessagerieListener, initNotificationListener } from './header/service/MessagerieAndNotificationRedDotService';
import Style from './App.module.css';
import {setBearerToken} from "_common/service/ApiService";

function App() {
    const [user, cnxState] = useAuth();
    const isConnected = user && cnxState === CONNECTED;
    const token = storageRead("token");
    if (token) {
        setBearerToken(token.token);
    }

    useEffect(() => {
        initMessagerieListener();
        initNotificationListener();
    }, []);

    return (
        <>
            {isConnected && <AccessibilityGlobalMenu />}
            <div className={Style.App}>
                <Router>
                    <HeaderFooterControl>
                        <Switch>
                            {routes.map((route) => (
                                <Route key={route.path} path={route.path} exact={route.exact}>
                                    {route.secured && isConnected && token ? (
                                        <Suspense fallback={<div></div>}>
                                                <main role="main">
                                                    <route.component />
                                                </main>
                                        </Suspense>
                                    ) : (
                                        <>
                                            {route.path === ROUTE_INSCRIPTION && <Inscription />}
                                            {route.path === ROUTE_REINITIALISATION_MDP && <AuthentificationResetForgottenPassword />}
                                            {route.path === ROUTE_NOUVEAU_MDP && <AuthentificationSetPassword />}
                                            {route.path === ROUTE_FIN_ADHESION && isConnected && token && <AdhesionEnding />}
                                            {route.path === ROUTE_ADHESION_PAIEMENT && <AdhesionPayement />}
                                            {route.path === (ROUTE_HOME || ROUTE_CONNEXION) && <AuthentificationConnexion />}
                                        </>
                                    )}
                                </Route>
                            ))}
                        </Switch>
                    </HeaderFooterControl>
                    <ToasterProvider />
                </Router>
            </div>
        </>
    );
}

export default App;