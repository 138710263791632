import { get } from "_common/service/ApiService";
import EventEmitter from "_common/service/EventEmitter";
import { useEffect, useState } from "react";
import useRefresh from "_common/hook/useRefresh";
import { CONNECTED, getState, getAuth, hasRole } from "_core/authentification/service/AuthService";

export const getUserMe = async () => {
    return await get(process.env.REACT_APP_API_URL + "/api/users/me");
}

export const getCheckLastMessageDatetime = async () => {
    return await get(process.env.REACT_APP_API_URL + `/api/discussions/check`);
}

export const getCheckLastNotificationDatetime = async () => {
    return await get(process.env.REACT_APP_API_URL + `/api/notifications/check`);
}

const messagerieEmitter = new EventEmitter();
export const addMessagerieListener = (listener) => messagerieEmitter.addListener(listener);
export const triggerMessagerieChanged = (message) => messagerieEmitter.trigger(message);
export const initMessagerieListener = async () => {

    const messagerieListener = (async () => {
        let auth = getAuth();
        const isConnected = getState() === CONNECTED;
        const user = auth.user;
        if (!auth || !isConnected || !hasRole(["ROLE_ADHERENTE_ACTIVE"], user)) {
            return
        }

        let check;
        check = await getCheckLastMessageDatetime();
        triggerMessagerieChanged(check);
    })
    setInterval(messagerieListener, 5000)
    messagerieListener();
}

const notificationEmitter = new EventEmitter();
export const addNotificationListener = (listener) => notificationEmitter.addListener(listener);
export const triggerNotificationChanged = (notification) => notificationEmitter.trigger(notification);
export const initNotificationListener = async () => {

    const notificationListener = (async () => {
        let auth = getAuth();
        const isConnected = getState() === CONNECTED;
        const user = auth.user;
        if (!auth || !isConnected || !hasRole(["ROLE_ADHERENTE_ACTIVE"], user)) {
            return
        }
        let check;
        check = await getCheckLastNotificationDatetime();
        triggerNotificationChanged(check);
    })

    setInterval(notificationListener, 5000)
    notificationListener();
}

export const getMembreDetail = async (id) => {
    return await get(process.env.REACT_APP_API_URL + `/api/adherentes/${id}/profil`);
}

export const useMembreDetail = id => {
    const [profil, setProfil] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [refresh, count] = useRefresh();

    useEffect(() => {
        const loadDetail = async () => {
            const profilData = await getMembreDetail(id);
            setProfil(profilData);
            setDataLoaded(true);
            window.scrollTo(0, 0);
        };
        id && loadDetail();
    }, [id, count]);
    return [profil, dataLoaded, refresh];
};