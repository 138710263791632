import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import "_core/css/theme.css";
import App from "_core/app/App";
import "_core/font/Lato-Regular.woff2";
import "_core/font/Lato-Italic.woff2";
import "_core/font/Lato-Bold.woff2";
import "_core/font/Lato-BoldItalic.woff2";
import "_core/font/Lato-Black.woff2";
import "_core/font/Lato-BlackItalic.woff2";
import 'leaflet/dist/leaflet.css';

const root = createRoot(document.getElementById('root'));
root.render(
    <App />
);