import Style from "./BannerRating.module.css";
import closeIcon from "_common/component/icon/close.svg";
import PropTypes from "prop-types";
import React, { useState, useEffect, useReducer } from "react";
import { saveRating, saveEditionRating } from "./service/BannerRatingServiceApi";
import envoyer from "_common/component/icon/envoyer.svg";
import { initialStateRating, ratingReducer } from "./BannerRatingReducer";
import clsx from "clsx";
import notificationService from "_common/component/toaster/NotificationService";
import { reloadCurrentUser } from "_core/authentification/service/AuthService";
import { year } from "_common/component/time/time";
import { storageRead, storageWrite } from '_common/service/StorageService';

const BannerRating = (membre) => {
    const [showBanner, setShowBanner] = useState(false);
    const [showRatingForm, setShowRatingForm] = useState(true);
    const [showCommentForm, setShowCommentForm] = useState(false);
    const [notation, setNotation] = useState(null);
    const [refreshBanner, setRefreshBanner] = useState(0);
    const [formCommentRating, dispatch] = useReducer(ratingReducer, initialStateRating);
    const bannerRatingLoadDate = storageRead("bannerRatingLoadDate") ?? storageWrite("bannerRatingLoadDate", new Date());
    const [isSubmitButtonClickable, setIsSubmitButtonClickable] = useState(true);
    const [isRadioButtonClickable, setIsRadioButtonClickable] = useState(true);
    const today = new Date();
    let monthsToShowBanner = process.env.REACT_APP_MONTH_RATING.split(', ').map(Number);

    const handleCloseBannerRatingClick = async () => {
        if (!membre.membre.derniereNotation) {
            await saveRating(notation);
        }
        reloadCurrentUser();
        setShowBanner(false);
    };

    const bannerLoadIsExpired = (date) => {
        const intervalDate = (today - new Date(date)) / 3600000;
        return intervalDate > 24;
    };

    if (bannerLoadIsExpired(new Date(bannerRatingLoadDate)) && monthsToShowBanner.includes(today.getMonth() + 1)) {
        storageWrite("bannerRatingLoadDate", new Date());
        setRefreshBanner(refreshBanner + 1);
    }

    useEffect(() => {
        if (monthsToShowBanner.includes(today.getMonth() + 1)) {
            if (membre.membre.derniereNotation) {
                const dateDerniereNotation = new Date(membre.membre.derniereNotation.createdAt);
                const monthDateDerniereNotation = dateDerniereNotation.getMonth() + 1;
                const yearDateDerniereNotation = dateDerniereNotation.getFullYear();
                if (!monthsToShowBanner.includes(monthDateDerniereNotation) || year !== yearDateDerniereNotation) {
                    setShowBanner(true);
                } else {
                    setShowBanner(false); 
                }
            } else {
                setShowBanner(true);
            }
        } else {
            setShowBanner(false);
        }
    }, [refreshBanner]);

    const notationValue = [ 1, 2, 3, 4, 5];

    const onChangeNotation = async (notation) => {
        setIsRadioButtonClickable(false);
        try {
            await saveRating(notation);
            reloadCurrentUser();
            setNotation(notation);
            setShowRatingForm(false);
            setShowCommentForm(true);
            setIsRadioButtonClickable(true);
        } catch (err) {
            notificationService.error("Une erreur est survenue.");
            setIsRadioButtonClickable(true);
        }
    }

    const commentChange = (e) => {
        dispatch({
            type: "comment",
            payload: e.target.value
        })
    }

    const submit = async (e) => {
        e.preventDefault();
        setIsSubmitButtonClickable(false);

        try {
            await saveEditionRating(membre.membre.derniereNotation.id, notation, formCommentRating.comment);
            reloadCurrentUser();
            setShowBanner(false);
            window.scrollTo(0, 0);
            notificationService.success("Votre participation est bien prise en compte.");
            setIsSubmitButtonClickable(true);
        } catch (err) {
            dispatch({ type: "error", payload: "Une erreur est survenue." });
            notificationService.error("Une erreur est survenue.");
            setIsSubmitButtonClickable(true);
        }
    }

    return showBanner ? (
        <div className={Style.BannerRating} id="banner-rating">
            {showRatingForm &&
                <form>
                    <fieldset className={Style.RadioButtonContainer}>
                        <legend>
                            Es-tu satisfaite de la plateforme collaborative Femmes des Territoires ?
                            <span className="sr-only">Notation de 1 (pas du tout satisfaite) à 5 (très satisfaite)</span>
                        </legend>
                        <div className={Style.NotationContainer}>
                            <span className={Style.SatifactionLevel} aria-hidden="true">Pas du tout satisfaite</span>
                            {notationValue.map((notationValue) => (
                                <>
                                    <input className="sr-only"
                                        type="radio"
                                        name="notation"
                                        id={`notation-${notationValue}`}
                                        onChange={e => onChangeNotation(e.target.value)}
                                        value={notationValue}
                                        key={notationValue}
                                        disabled={!isRadioButtonClickable}
                                    />
                                    <label className={!isRadioButtonClickable ? Style.RadionButtonRatingDisabled : Style.RadionButtonRating} htmlFor={`notation-${notationValue}`} key={notationValue + 1}>
                                        {notationValue}
                                    </label>
                                </>
                            ))}
                            <span className={Style.SatifactionLevel} aria-hidden="true">Très satisfaite</span>
                        </div>

                    </fieldset>
                </form>
            }

            {showCommentForm &&
                <form className={Style.CommentRatingForm} onSubmit={submit}>
                    <label className={Style.CommentRatingLabel} htmlFor="comment-rating">Merci ! Comment pourrions-nous améliorer notre plateforme pour encore mieux répondre à tes besoins ?</label>
                    <div className={Style.InputContainer}>
                        <input id="comment-rating"
                            className={Style.CommentRatingInput}
                            type="text"
                            placeholder="Ton commentaire (facultatif)"
                            onChange={commentChange}
                            maxLength="255"
                            name="comment"
                            value={formCommentRating.comment}
                        />

                        <button className={!isSubmitButtonClickable ? Style.ButtonDesactive : Style.SubmitButton} disabled={!isSubmitButtonClickable} type="submit">
                            <img src={envoyer} alt="envoyer mon commentaire" />
                        </button>
                    </div>
                    {formCommentRating.error && <strong className={clsx("special-italic-text", "red-error")}>{formCommentRating.error}</strong>}
                </form>
            }

            <button className={Style.BannerRatingCloseButton} onClick={() => handleCloseBannerRatingClick()}>
                <img src={closeIcon} alt="Fermer pour ne plus voir ce message" />
            </button>
        </div>
    ) 
    : null
}

BannerRating.propTypes = {
    membre: PropTypes.object,
};

export default BannerRating;