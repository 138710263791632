import { guid } from "_common/service/FunUtil";

const DEFAULT_DURATION_MS = 5000;

class NotificationServiceImpl {

  constructor() {
    this.notificationProviderHandler = undefined;
    this.open = this.open.bind(this);
    this.setNotificationProvider = this.setNotificationProvider.bind(this);
    this.warn = this.warn.bind(this);
    this.info = this.info.bind(this);
    this.error = this.error.bind(this);
    this.success = this.success.bind(this);
  }

  open(
    message,
    typeNotification,
    duration
  ) {
    return (
      (this.notificationProviderHandler &&
        this.notificationProviderHandler({
          uid: guid(),
          message,
          typeNotification,
          duration,
        })) ||
      (() => { })
    );
  }

  setNotificationProvider(
    handler/*: (conf: NotificationConfig) => () => void*/
  ) {
    if (this.notificationProviderHandler) {
      // eslint-disable-next-line no-console
      console.error("The NotificationProvider handles only one Handler...");
    }
    this.notificationProviderHandler = handler;
  }

  warn(message, duration) {
    return this.open(message, "is-warning", duration || DEFAULT_DURATION_MS);
  }

  async info(message, duration) {
    return this.open(message, "is-info", duration || DEFAULT_DURATION_MS);
  }

  error(message, duration) {
    return this.open(message, "is-danger", duration || 5000);
  }

  success(message, duration) {
    return this.open(message, "is-success", duration || DEFAULT_DURATION_MS);
  }
}

const NotificationService = new NotificationServiceImpl();

export default NotificationService;
