import clsx from "clsx";
import Style from "./AccessibilityGlobalMenu.module.css";

const AccessibilityGlobalMenu = () => {
    return <nav className={Style.Container} role="navigation" aria-label="navigation accessibilité">
        <ul>
            <li><a href="#main" className={clsx("visually-hidden", "visually-hidden-focusable")}>Aller au contenu</a></li>
            <li><a href="#search" className={clsx("visually-hidden", "visually-hidden-focusable")}>Aller à la recherche</a></li>
            <li><a href="#navigation-principale" className={clsx("visually-hidden", "visually-hidden-focusable")}>Aller à la navigation principale</a></li>
        </ul>
    </nav>
}

export default AccessibilityGlobalMenu;