import Style from "./AdhesionEnding.module.css";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_HOME, ROUTE_FIN_ADHESION } from "_core/router/routes";
import clsx from "clsx";
import { changeStatutPaiement } from "_core/adhesion/service/AdhesionService";
import journeyImage from "_common/component/icon/journey.jpeg";

const AdhesionPayement = () => {
    const retourHelloAsso = useLocation().search;
    const code = new URLSearchParams(retourHelloAsso).get("code");
    const checkoutId = new URLSearchParams(retourHelloAsso).get("checkoutIntentId");

    let description = "";
    let bouton = "";

    if (code && code === "succeeded") {
        description = "Votre adhésion a bien été renouvelée !";
        bouton = <Link className={Style.BoutonPaiement} to={ROUTE_HOME}>Je me connecte</Link>;
        changeStatutPaiement(checkoutId);
    } else if (code && code === "refused") {
        description = "Votre paiement a été refusé.";
        bouton = <Link className={Style.BoutonPaiement} to={ROUTE_FIN_ADHESION} >Retour</Link>;
    }
    else {
        description = "Votre paiement a été annulé.";
        bouton = <Link className={Style.BoutonPaiement} to={ROUTE_FIN_ADHESION} >Retour</Link>;
    }

    return (
        <div className={Style.PageContainer}>
            <div className={Style.Content}>
                <div className={Style.LeftSideSection}>
                    <div className={Style.LeftSideContent}>
                        <p className={clsx(Style.ValidationPaiement, (code != "succeeded") ? Style.RedValidationPaiement : "")}>{description}</p>
                        {bouton}
                    </div>
                </div>
                <div className={Style.RightSideSection}>
                    <img src={journeyImage} alt="" />
                </div>
            </div>
        </div>
    )
};

export default AdhesionPayement;