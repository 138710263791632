import React, { useEffect } from "react";
import clsx from "clsx";
import Style from "./Toaster.module.css";
import PropTypes from "prop-types";

const Toaster = ({ typeNotification, onClose, duration, children }) => {
  useEffect(() => {
    if (duration > 0) {
      const t = setTimeout(() => {
        onClose(undefined);
      }, duration);

      return () => clearTimeout(t); // return clear timeout handler
    }
    return undefined;
  }, [duration, onClose]);

  return (
    <div className={clsx("notification", Style.Notification, Style[typeNotification])} style={{ marginBottom: 8 }}>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <button className={Style.Delete} onClick={onClose} type="button" />
      {children}
    </div>
  );
};

Toaster.propTypes = {
  typeNotification: PropTypes.string,
  onClose: PropTypes.func,
  duration: PropTypes.number,
  children: PropTypes.any,
  color: PropTypes.string,
};

export default Toaster;
