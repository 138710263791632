import React from "react";
import PropTypes from "prop-types";
import Style from "./AppContentLayout.module.css";
import clsx from "clsx";

const AppContentLayout = ({ children, isMobile }) => {
  const showLeftMenu = !isMobile;
  const [menu, ...content] = React.Children.toArray(children);

  return (
    <div className={clsx(Style.AppMenuContentContainer, "main-container")}>
      {showLeftMenu && (
        <div className={Style.AppLeftMenu}>
          <div className={clsx(Style.AppLeftMenuContainer, "left-menu-container")}>{menu}</div>
        </div>
      )}
      <div className={Style.AppContent}>{content}</div>
    </div>
  );
};
AppContentLayout.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool,
};

export default AppContentLayout;
