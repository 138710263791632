import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppHeader from '_core/app/header/AppHeader';
import AppFooterDesktop from '_core/app/footer-desktop/AppFooterDesktop';
import AppLowerMenuMobile from './menu-lower-mobile/AppLowerMenuMobile';
import AppLeftMenu from './left-menu/AppLeftMenu';
import { hasRole, CONNECTED, getAuth, getState } from '_core/authentification/service/AuthService';
import { storageRead } from '_common/service/StorageService';
import {
    ROUTE_ADHESION_PAIEMENT,
    ROUTE_CONNEXION,
    ROUTE_FIN_ADHESION,
    ROUTE_INSCRIPTION,
    ROUTE_REINITIALISATION_MDP,
    ROUTE_NOUVEAU_MDP, ROUTE_HOME,
} from "_core/router/routes";
import useMedia from "_common/hook/useMedia";
import AppContentLayout from "./layout/AppContentLayout";
import PropTypes from "prop-types";
import BannerRating from './banner-rating/BannerRating';

const HeaderFooterControl = ({ children }) => {
    const [showHeader, setShowHeader] = useState(false);
    const [showFooter, setShowFooter] = useState(false);

    const [showLeftMenu, setShowLeftMenu] = useState(false);
    const location = useLocation();
    const history = useHistory();
    let auth = getAuth();
    const isConnected = getState() === CONNECTED;
    const user = auth.user;
    const token = storageRead("token");
    const isMobile = useMedia(["(max-width: 1023px)", "(min-width: 1024px)"], [true, false], true);

    const noHeaderRoutes = [ROUTE_CONNEXION, ROUTE_INSCRIPTION, ROUTE_REINITIALISATION_MDP, ROUTE_NOUVEAU_MDP];
    const noFooterRoutes = [ROUTE_CONNEXION, ROUTE_INSCRIPTION, ROUTE_REINITIALISATION_MDP, ROUTE_NOUVEAU_MDP];

    const noLeftMenuRoutes = [ROUTE_FIN_ADHESION, ROUTE_ADHESION_PAIEMENT, ROUTE_CONNEXION, ROUTE_INSCRIPTION, ROUTE_REINITIALISATION_MDP, ROUTE_NOUVEAU_MDP];

    useEffect(() => {
        const shouldShowHeader = !noHeaderRoutes.includes(location.pathname) && isConnected && token;
        const shouldShowFooter = !noFooterRoutes.includes(location.pathname) && isConnected && token;

        const shouldShowLeftMenu = !noLeftMenuRoutes.includes(location.pathname) && user && hasRole(["ROLE_ADHERENTE_ACTIVE"], user);

        setShowHeader(shouldShowHeader);
        setShowFooter(shouldShowFooter);
        setShowLeftMenu(shouldShowLeftMenu);
    }, [location, isConnected, token, user]);

    if (!isConnected && ![ROUTE_HOME, ROUTE_ADHESION_PAIEMENT, ROUTE_INSCRIPTION, ROUTE_REINITIALISATION_MDP, ROUTE_NOUVEAU_MDP].includes(location.pathname) && !location.pathname.includes(ROUTE_CONNEXION) || isConnected && location.pathname.includes(ROUTE_CONNEXION)) {
        history.push(ROUTE_HOME);
    }

    return (
        <>
            {user && isConnected && showHeader && <AppHeader user={user} isMobile={isMobile} />}
            {showLeftMenu && user && isConnected && hasRole(["ROLE_ADHERENTE_ACTIVE"], user) ? <AppContentLayout isMobile={isMobile} isConnected={isConnected}>
                <AppLeftMenu user={user} />
                {children}
            </AppContentLayout> :
                <>
                    {children}
                </>
            }
            {user && isConnected && showFooter && (
                    isMobile ? 
                        hasRole(["ROLE_ADHERENTE_ACTIVE"], user) ?
                        <>
                            <BannerRating membre={user.adherente} />
                            <AppLowerMenuMobile /> 
                        </>
                        :
                        null
                    :   
                    <>
                        {hasRole(["ROLE_ADHERENTE_ACTIVE"], user) && <BannerRating membre={user.adherente} />}
                        <AppFooterDesktop />
                    </>
            )}
        </>
    );
};
HeaderFooterControl.propTypes = {
    children: PropTypes.node,
    user: PropTypes.object,
};
export default HeaderFooterControl;
