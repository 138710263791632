import React from "react";
import PropTypes from "prop-types";

const Territoire = ({ color = "#1D1D1F" }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <g clipPath="url(#clip0)">
        <path
          d="M0.820312 28H9.19171C9.64474 28 10.012 27.6327 10.012 27.1797V26.2657C10.012 24.3694 8.95202 22.7159 7.39342 21.8666C7.94697 21.279 8.28729 20.4883 8.28729 19.6191C8.28729 17.8099 6.81532 16.3379 5.00604 16.3379C3.19676 16.3379 1.72479 17.8099 1.72479 19.6191C1.72479 20.4883 2.06511 21.279 2.61866 21.8666C1.06001 22.7159 0 24.3694 0 26.2657V27.1797C0 27.6327 0.367281 28 0.820312 28ZM3.36541 19.6191C3.36541 18.7145 4.1014 17.9785 5.00604 17.9785C5.91068 17.9785 6.64666 18.7145 6.64666 19.6191C6.64666 20.5238 5.91068 21.2598 5.00604 21.2598C4.1014 21.2598 3.36541 20.5238 3.36541 19.6191ZM5.00604 22.9004C6.76353 22.9004 8.3714 24.2781 8.3714 26.3594H1.64062C1.64062 24.289 3.23914 22.9004 5.00604 22.9004Z"
          fill={color}
        />
        <path
          d="M25.3812 21.8666C25.9347 21.279 26.275 20.4883 26.275 19.6191C26.275 17.8099 24.8031 16.3379 22.9938 16.3379C21.1845 16.3379 19.7125 17.8099 19.7125 19.6191C19.7125 20.4883 20.0528 21.279 20.6064 21.8666C19.0478 22.7159 17.9878 24.3693 17.9878 26.2657V27.1797C17.9878 27.6327 18.3551 28 18.8081 28H27.1795C27.6325 28 27.9998 27.6327 27.9998 27.1797V26.2657C27.9998 24.3694 26.9398 22.7159 25.3812 21.8666ZM21.3531 19.6191C21.3531 18.7145 22.0891 17.9785 22.9937 17.9785C23.8984 17.9785 24.6343 18.7145 24.6343 19.6191C24.6343 20.5238 23.8984 21.2598 22.9937 21.2598C22.0891 21.2598 21.3531 20.5238 21.3531 19.6191ZM26.3591 26.3594H19.6284C19.6284 24.289 21.2269 22.9004 22.9937 22.9004C24.7512 22.9004 26.3591 24.278 26.3591 26.3594Z"
          fill={color}
        />
        <path
          d="M19.0062 10.8418V9.92786C19.0062 8.03146 17.9461 6.37798 16.3876 5.52874C16.941 4.94107 17.2814 4.1504 17.2814 3.28125C17.2814 1.47197 15.8094 0 14.0001 0C12.1908 0 10.7189 1.47197 10.7189 3.28125C10.7189 4.1504 11.0592 4.94107 11.6127 5.52874C10.0541 6.37798 8.99414 8.03146 8.99414 9.92786V10.8418C8.99414 11.2948 9.36142 11.6621 9.81445 11.6621H18.1858C18.6389 11.6621 19.0062 11.2948 19.0062 10.8418ZM12.3595 3.28125C12.3595 2.37661 13.0955 1.64062 14.0001 1.64062C14.9048 1.64062 15.6407 2.37661 15.6407 3.28125C15.6407 4.18589 14.9048 4.92188 14.0001 4.92188C13.0955 4.92188 12.3595 4.18589 12.3595 3.28125ZM17.3655 10.0215H10.6347C10.6347 7.95107 12.2332 6.5625 14.0001 6.5625C15.7575 6.5625 17.3655 7.94008 17.3655 10.0215Z"
          fill={color}
        />
        <path
          d="M17.3142 20.7287C16.2425 21.0808 15.1124 21.2592 13.9554 21.2592C12.839 21.2592 11.7457 21.0925 10.7061 20.7637C10.274 20.6272 9.81314 20.8665 9.67659 21.2984C9.53998 21.7303 9.7794 22.1913 10.2113 22.3279C11.4112 22.7074 12.6709 22.8998 13.9554 22.8998C15.2866 22.8998 16.589 22.6938 17.8262 22.2874C18.2567 22.146 18.491 21.6824 18.3496 21.252C18.2082 20.8216 17.7447 20.5873 17.3142 20.7287Z"
          fill={color}
        />
        <path
          d="M6.4385 6.79804C5.53255 7.67135 4.76239 8.68656 4.14939 9.81559C3.51414 10.9856 3.0738 12.2284 2.84061 13.5096C2.74923 14.0117 3.1345 14.4769 3.64862 14.4769C4.03734 14.4769 4.38264 14.1994 4.45471 13.8034C4.65673 12.6936 5.0391 11.6153 5.59117 10.5984C6.124 9.61718 6.79211 8.73595 7.5771 7.97929C7.90325 7.66489 7.91282 7.14558 7.59843 6.81937C7.28397 6.49321 6.76471 6.48364 6.4385 6.79804Z"
          fill={color}
        />
        <path
          d="M21.5615 6.79806C21.2353 6.48361 20.716 6.49324 20.4016 6.81939C20.0872 7.1456 20.0967 7.66491 20.4229 7.97931C21.2079 8.73597 21.8761 9.61715 22.4088 10.5985C22.961 11.6154 23.3433 12.6937 23.5453 13.8034C23.6263 14.2486 24.0529 14.5447 24.4992 14.4635C24.9449 14.3824 25.2405 13.9553 25.1594 13.5096C24.9262 12.2285 24.4859 10.9856 23.8506 9.81561C23.2376 8.68659 22.4675 7.67131 21.5615 6.79806Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
Territoire.propTypes = {
  color: PropTypes.string,
};

export default Territoire;
