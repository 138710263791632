import React from "react";
import PropTypes from "prop-types";

const AdminEtCoordinatrice = ({ color = "#1D1D1F" }) => {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <path
        d="M18.6667 5.58268C19.2682 5.58268 19.7556 5.81601 20.1393 6.27101C20.5333 6.74935 20.7407 7.27435 20.7407 7.91601V20.7493C20.7407 21.391 20.5333 21.916 20.1393 22.3943C19.7556 22.8493 19.2682 23.0827 18.6667 23.0827H2.07407C1.47259 23.0827 0.985185 22.8493 0.601481 22.3943C0.207407 21.916 0 21.391 0 20.7493V7.91601C0 7.27435 0.207407 6.74935 0.601481 6.27101C0.985185 5.81601 1.47259 5.58268 2.07407 5.58268H6.22222V3.24935C6.22222 2.57268 6.42963 2.02435 6.8237 1.59268C7.20741 1.14935 7.69482 0.916016 8.2963 0.916016H12.4444C13.0459 0.916016 13.5333 1.14935 13.917 1.59268C14.3111 2.02435 14.5185 2.57268 14.5185 3.24935V5.58268H18.6667ZM2.07407 7.91601V20.7493H18.6667V7.91601H2.07407ZM12.4444 5.58268V3.24935H8.2963V5.58268H12.4444Z"
        fill={color}
      />
    </svg>
  );
};

AdminEtCoordinatrice.propTypes = {
  color: PropTypes.string,
};
export default AdminEtCoordinatrice;
