export const initialStateRating = {
    comment: "",
    error: ""
};

export const ratingReducer = (formCommentRating, action) => {
    switch (action.type) {
        case 'comment':
            return { ...formCommentRating, comment: action.payload }
        case 'error':
            return { ...formCommentRating, error: action.payload }
        default:
            return formCommentRating;
    }
};
