import Style from "./AuthentificationSetPassword.module.css";
import LogoFDT from "_common/component/icon/logoFDT.svg";
import { resetPassword } from "_core/authentification/service/ResetPasswordService";
import clsx from "clsx";
import { useState } from "react";
import { ROUTE_HOME } from "_core/router/routes";
import { Link, useLocation } from "react-router-dom";
import eye from "_common/component/icon/eye.svg";

const AuthentificationSetPassword = () => {
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [errorConf, setErrorConf] = useState('');
    const [errorRegEx, setErrorRegEx] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [submitMessageShown, setSubmitMessageShown] = useState(false);
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W)(?=.{8,})");
    const location = useLocation();
    const resetToken = location.search.split("=").pop();

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        setErrorConf("");
    };
    const onChangeConfPassword = (e) => {
        setConfPassword(e.target.value);
        setErrorConf("");
    };
    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };

    const submit = async (e) => {
        e.preventDefault();
        if (password !== confPassword) {
            setErrorConf("Erreur : Les mots de passe ne sont pas identiques.");
        }
        if (!password.match(passwordRegex)) {
            setErrorRegEx("Erreur : Veuillez entrer un mot de passe valide.");
        }
        if (password === confPassword && password.match(passwordRegex)) {
            try {
                await resetPassword(resetToken, password);
                setSubmitMessageShown(true);
            } catch (err) {
                setPassword('');
                setConfPassword('');
                setErrorRegEx("Erreur : Veuillez entrer un mot de passe valide.");
            }
        }
        setPassword('');
        setConfPassword('');
    }

    return <div className={Style.BlueBackground}>
        <header className="sr-only">
            <h1 className="sr-only">Réinitialiser mon mot de passe</h1>
        </header>
        <div className={Style.WhiteContainer}>
            <Link to={ROUTE_HOME} className={Style.Logo}>
                <img src={LogoFDT} alt="Retour à la page de connexion" />
            </Link>
            {submitMessageShown &&
                <div className={Style.TitreContainer}>
                    <h1 className={Style.LabelSubmitMessage}>Votre mot de passe a été réinitialisé.</h1>
                    <div className={Style.ButtonContainer}>
                        <Link to={ROUTE_HOME} className={Style.ButtonSubmit}>
                            Je me connecte
                        </Link>
                    </div>
                </div>}
            {!submitMessageShown && <div className={Style.FormContainer}>
                <h1 className={Style.LabelTitre}>Choisissez votre nouveau mot de passe</h1>
                <form>
                    <p className={Style.TextRequis} aria-hidden="true">* champs obligatoires</p>
                    <p className={errorRegEx ? clsx(Style.SpecialCharactersList, Style.Error) : Style.SpecialCharactersList} >Le mot de passe doit contenir au minimum 8 caractères dont au moins une minuscule, une majuscule, un chiffre et un caractère spécial.</p>
                    {errorConf && <strong className={Style.Error}>{errorConf}</strong>}
                    <label htmlFor="a11y-password" className={Style.LabelPassword}>Définir mon nouveau mot de passe <span aria-hidden="true">*</span></label>
                    <div className={Style.ContainerPassword}>
                        <input id="a11y-password"
                            autoComplete="new-password"
                            type={passwordShown ? "text" : "password"}
                            className={errorConf ? clsx(Style.InputPassword, "form-control", Style.ErrorField) : clsx(Style.InputPassword, "form-control")}
                            value={password}
                            required
                            onChange={onChangePassword}
                        />
                        <button type="button" className={`button-icon ${Style.ButtonEye}`} onClick={togglePasswordVisiblity}>
                            <img src={eye} alt="" aria-hidden="true" />
                            <span className="sr-only">
                                {passwordShown ? "cacher" : "voir"} le mot de passe
                            </span>
                        </button>
                    </div>
                    <label htmlFor="a11y-password-confirmation" className={Style.LabelPassword}>Confirmer mon nouveau mot de passe <span aria-hidden="true">*</span></label>
                    <div className={Style.ContainerPassword}>
                        <input id="a11y-password"
                            autoComplete="new-password"
                            type={passwordShown ? "text" : "password"}
                            className={errorConf ? clsx(Style.InputPassword, "form-control", Style.ErrorField) : clsx(Style.InputPassword, "form-control")}
                            value={confPassword}
                            required
                            onChange={onChangeConfPassword}
                        />
                        <button type="button" className={`button-icon ${Style.ButtonEye}`} onClick={togglePasswordVisiblity}>
                            <img src={eye} alt="" aria-hidden="true" />
                            <span className="sr-only">
                                {passwordShown ? "cacher" : "voir"} le mot de passe
                            </span>
                        </button>
                    </div>
                    <div className={Style.ButtonContainer}>
                        <button type="submit" onClick={submit} className={Style.ButtonSubmit}>Réinitialiser</button>
                    </div>
                </form>
            </div>}
        </div>
    </div>
}

export default AuthentificationSetPassword;