import PropTypes from "prop-types";

const YoutubeIcon = ({color = "#1D1D1F"}) => {
    return (
        <svg version="1.0"
             xmlns="http://www.w3.org/2000/svg"
             width="24px"
             height="24px"
             viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet"
             role="img"
             aria-hidden="true">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="#000000"
               stroke="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1920 4489 c-450 -10 -1000 -37 -1136 -55 -188 -24 -319 -80 -446
           -191 -133 -114 -206 -245 -262 -470 -55 -220 -71 -488 -71 -1213 0 -859 25
           -1147 121 -1387 111 -276 317 -429 644 -477 167 -24 333 -35 840 -52 811 -27
           1688 -20 2423 21 381 22 456 34 591 101 182 91 302 229 377 436 93 252 113
           500 113 1358 0 826 -22 1108 -103 1344 -89 256 -267 426 -523 500 -108 31
           -224 43 -608 61 -665 32 -1295 39 -1960 24z m1222 -299 c570 -14 1138 -44
           1233 -66 84 -20 144 -49 211 -102 65 -51 106 -116 143 -227 68 -204 86 -469
           85 -1255 0 -817 -19 -1037 -105 -1260 -63 -165 -177 -255 -363 -291 -63 -12
           -489 -35 -906 -50 -424 -14 -1232 -15 -1680 0 -908 29 -1056 47 -1199 142 -80
           53 -126 118 -167 237 -70 203 -89 468 -89 1242 0 766 18 1018 87 1223 38 114
           66 163 126 219 118 111 196 133 542 153 650 38 1397 50 2082 35z"
                    fill={color}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2034 3416 c-46 -21 -100 -81 -113 -129 -9 -30 -11 -221 -9 -699 l3
           -656 30 -44 c43 -60 105 -93 177 -92 54 1 76 11 640 314 320 172 596 323 611
           334 47 34 79 98 79 158 1 69 -20 117 -68 157 -22 18 -248 147 -504 288 -723
           396 -698 383 -761 383 -30 -1 -68 -7 -85 -14z m631 -594 c193 -106 359 -197
           368 -204 15 -11 -39 -43 -398 -235 -228 -123 -417 -223 -420 -223 -3 0 -5 205
           -5 456 l0 456 53 -30 c28 -16 210 -115 402 -220z"
                    fill={color}
                />
            </g>
        </svg>
    )
};
YoutubeIcon.propTypes = {
    color: PropTypes.string,
};

export default YoutubeIcon;

