import Style from './ElementsJuridiques.module.css';
import { routesWithElementsJuridiques } from "_core/router/routes";

const ElementsJuridiques = () => {
    return (
        <ul className={Style.ListElementsJuridiques}>
            {routesWithElementsJuridiques.map(({ path, key, elementsJuridiques }) => {
                return (
                    <li key={key} className={Style.LiensCliquables}>
                        <a className={Style.ElementsJuridiquesLien} href={path} target="_blank" rel="noreferrer">
                            {elementsJuridiques?.label}
                        </a>
                    </li>
                );
            })}
        </ul>
    )
}

export default ElementsJuridiques;
