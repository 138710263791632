import { useState } from "react";
import Style from "./AdhesionEnding.module.css";
import journeyImage from "_common/component/icon/journey.jpeg";
import { adhesionPaiement } from "_core/adhesion/service/AdhesionService";
import clsx from "clsx";
import { getAuth, hasRole } from "_core/authentification/service/AuthService";

const AdhesionEnding = () => {
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState('');
    const checkReduction = () => {
        setChecked(!checked);
    }
    let auth = getAuth();
    const user = auth.user;

    const submit = async (e) => {
        e.preventDefault();

        try {
            let $lienPaiement = '';
            $lienPaiement = await adhesionPaiement(checked);
            if ($lienPaiement) {
                window.location.href = $lienPaiement;
            } else {
                setError('Une erreur est survenue.')
            }
        } catch (err) {
            setError('Une erreur est survenue.')
        }
    }

    return (
        <div className={Style.PageContainer}>
            <div className={Style.Content}>
                <div className={Style.LeftSideSection}>
                    <div className={Style.LeftSideContent}>
                        {!hasRole(["ROLE_ADHERENTE_ACTIVE"], user) && <p>Ton adhésion est arrivée à son terme :</p>}
                        <form className={Style.ReductionForm} action="/adhesion" method="post" onSubmit={submit}>
                            <h1>Je prolonge mon adhésion pour 60€/an</h1>
                            <div className={Style.CheckReductionContainer}>
                                <input
                                    id="a11y-justification-tarif-reduit"
                                    type="checkbox"
                                    value={checked}
                                    onChange={checkReduction}
                                    className="sr-only"
                                />
                                <label htmlFor="a11y-justification-tarif-reduit">Adhésion à 30€ car je peux justifier bénéficier soit du RSA, de l'AAH, ASS, ASPA, ADA, ASV, ASPA, RSO, AV ou ATA.</label>
                            </div>
                            {error && <p className={Style.Error}>{error}</p>}
                            <button className={clsx(Style.ButtonRenouvellement, "button-white", "button")} type="submit">Je prolonge</button>
                        </form>
                        <p className={Style.WarningHelloAsso}>Tu vas être redirigée automatiquement vers le site de HelloAsso afin d'effectuer le paiement en ligne en toute sécurité.<br />
                        Cette redirection peut prendre quelques instants, merci de patienter.</p>
                    </div>
                </div>
                <div className={Style.RightSideSection} aria-hidden="true">
                    <img src={journeyImage} alt="" />
                </div>
            </div>
        </div>
    )
};

export default AdhesionEnding;
