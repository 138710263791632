import { Link } from "react-router-dom";
import Style from "./AppHeader.module.css";
import megaphone from "_common/component/icon/megaphone.svg";
import closeIcon from "_common/component/icon/close.svg";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useBannerDetail } from "_core/app/header/service/BannerService";
import { saveClickOnCloseBanner } from "_core/app/header/service/BannerServiceApi";
import { storageRead, storageWrite } from '_common/service/StorageService';

const BannerNotification = ({BannerNotificationVisibilityChanger}) => {
    const [showBanner, setShowBanner] = useState(false);
    const [refreshBanner, setRefreshBanner] = useState(0);
    const [banner, dataLoaded] = useBannerDetail(refreshBanner);
    const bannerLoadDate = storageRead("bannerLoadDate") ?? storageWrite("bannerLoadDate", new Date());
    const today = new Date();

    const handleCloseBannerClick = async (bannerId) => {
        await saveClickOnCloseBanner(bannerId);
        setShowBanner(false);
        BannerNotificationVisibilityChanger(false);
    };

    const bannerLoadIsExpired = (date) => {
        const intervalDate = (today - new Date(date)) / 3600000;
        return intervalDate > 24;
    };

    if (bannerLoadIsExpired(new Date(bannerLoadDate))) {
        storageWrite("bannerLoadDate", new Date());
        setRefreshBanner(refreshBanner + 1);
    }

    useEffect(() => {
        if (dataLoaded && banner) {
            setShowBanner(true);
            BannerNotificationVisibilityChanger(true);
        }

        if (!banner && !dataLoaded) {
            setShowBanner(false);
            BannerNotificationVisibilityChanger(false);
        }
    }, [banner, refreshBanner]);

    return dataLoaded && showBanner ? (
        <div className={Style.BannerNotification} id="banner-notification">
            <div className={Style.BannerImage}>
                <span>Nouveau !</span>
                <img src={megaphone} alt="" />
            </div>
            <div className={Style.BannerDescription}>
                <div>
                    <div>
                        <p>{banner.texteLigne1}</p>
                        {banner.texteLigne2 && <p>{banner.texteLigne2}</p>}
                    </div>
                    {banner.texteBouton && <Link className="button-white button" to={banner.lienBouton}>{banner.texteBouton}</Link>}
                </div>
            </div>
            <button className={Style.BannerNotificationCloseButton} onClick={() => handleCloseBannerClick(banner.id)}>
                <span>Ne plus voir ce message</span>
                <img src={closeIcon} alt="" />
            </button>
        </div>
    ) : null
}

BannerNotification.propTypes = {
    BannerNotificationVisibilityChanger: PropTypes.func.isRequired
};

export default BannerNotification;