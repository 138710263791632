import { useEffect } from "react";
import { isFunction } from "_common/service/FunUtil";

const useOnClickOutside = (refOrPredicat, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (isFunction(refOrPredicat)) {
        if (refOrPredicat(event.target, event)) {
          handler(event);
        }
        return;
      } else if (refOrPredicat.current && refOrPredicat.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [refOrPredicat, handler]);

  useEffect(() => {
    const listener = (k) => {
      if (k.code === "Escape") {
        handler(event);
      }
    };

    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handler]);
};

export { useOnClickOutside };
