/**
 * Basic event emitter implementation
 *
 * Warning : !!! removeListener function must be called to avoid memory leak !!!
 * Warning : !!! removeListener function must be called to avoid memory leak !!!
 * Warning : !!! removeListener function must be called to avoid memory leak !!!
 *
 * Advice : use a Custom Hook with a useEffect inside to add and remove listener
 *          then use this hook in the components to listen on events without the need to add and remove listener inside the component
 *
 */
class EventEmitter {

  constructor() {
    this._listeners = [];
    this.addListener = this.addListener.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.trigger = this.trigger.bind(this);
  }

  addListener(listener) {
    this._listeners.push(listener);
    return () => this.removeListener(listener);
  }

  removeListener(listener) {
    const idx = this._listeners.indexOf(listener);
    if (idx > -1) {
      this._listeners.splice(idx, 1);
    }
  }

  trigger(message) {
    for (let i = 0; i < this._listeners.length; i++) {
      this._listeners[i](message);
    }
  }
}

export default EventEmitter;