import React from "react";
import PropTypes from "prop-types";

const Calendar = ({ color = "#1D1D1F" }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <path
        d="M7.875 13.6172H6.125C5.67197 13.6172 5.30469 13.9845 5.30469 14.4375C5.30469 14.8905 5.67197 15.2578 6.125 15.2578H7.875C8.32803 15.2578 8.69531 14.8905 8.69531 14.4375C8.69531 13.9845 8.32803 13.6172 7.875 13.6172Z"
        fill={color}
      />
      <path
        d="M7.875 17.1172H6.125C5.67197 17.1172 5.30469 17.4845 5.30469 17.9375C5.30469 18.3905 5.67197 18.7578 6.125 18.7578H7.875C8.32803 18.7578 8.69531 18.3905 8.69531 17.9375C8.69531 17.4845 8.32803 17.1172 7.875 17.1172Z"
        fill={color}
      />
      <path
        d="M7.875 20.6172H6.125C5.67197 20.6172 5.30469 20.9845 5.30469 21.4375C5.30469 21.8905 5.67197 22.2578 6.125 22.2578H7.875C8.32803 22.2578 8.69531 21.8905 8.69531 21.4375C8.69531 20.9845 8.32803 20.6172 7.875 20.6172Z"
        fill={color}
      />
      <path
        d="M14.875 13.6172H13.125C12.672 13.6172 12.3047 13.9845 12.3047 14.4375C12.3047 14.8905 12.672 15.2578 13.125 15.2578H14.875C15.328 15.2578 15.6953 14.8905 15.6953 14.4375C15.6953 13.9845 15.328 13.6172 14.875 13.6172Z"
        fill={color}
      />
      <path
        d="M14.875 17.1172H13.125C12.672 17.1172 12.3047 17.4845 12.3047 17.9375C12.3047 18.3905 12.672 18.7578 13.125 18.7578H14.875C15.328 18.7578 15.6953 18.3905 15.6953 17.9375C15.6953 17.4845 15.328 17.1172 14.875 17.1172Z"
        fill={color}
      />
      <path
        d="M14.875 20.6172H13.125C12.672 20.6172 12.3047 20.9845 12.3047 21.4375C12.3047 21.8905 12.672 22.2578 13.125 22.2578H14.875C15.328 22.2578 15.6953 21.8905 15.6953 21.4375C15.6953 20.9845 15.328 20.6172 14.875 20.6172Z"
        fill={color}
      />
      <path
        d="M21.875 13.6172H20.125C19.672 13.6172 19.3047 13.9845 19.3047 14.4375C19.3047 14.8905 19.672 15.2578 20.125 15.2578H21.875C22.328 15.2578 22.6953 14.8905 22.6953 14.4375C22.6953 13.9845 22.328 13.6172 21.875 13.6172Z"
        fill={color}
      />
      <path
        d="M21.875 17.1172H20.125C19.672 17.1172 19.3047 17.4845 19.3047 17.9375C19.3047 18.3905 19.672 18.7578 20.125 18.7578H21.875C22.328 18.7578 22.6953 18.3905 22.6953 17.9375C22.6953 17.4845 22.328 17.1172 21.875 17.1172Z"
        fill={color}
      />
      <path
        d="M21.875 20.6172H20.125C19.672 20.6172 19.3047 20.9845 19.3047 21.4375C19.3047 21.8905 19.672 22.2578 20.125 22.2578H21.875C22.328 22.2578 22.6953 21.8905 22.6953 21.4375C22.6953 20.9845 22.328 20.6172 21.875 20.6172Z"
        fill={color}
      />
      <path
        d="M25.5391 3.55469H23.5703V2.1875C23.5703 1.73447 23.203 1.36719 22.75 1.36719C22.297 1.36719 21.9297 1.73447 21.9297 2.1875V3.55469H14.8203V2.1875C14.8203 1.73447 14.453 1.36719 14 1.36719C13.547 1.36719 13.1797 1.73447 13.1797 2.1875V3.55469H6.07031V2.1875C6.07031 1.73447 5.70303 1.36719 5.25 1.36719C4.79697 1.36719 4.42969 1.73447 4.42969 2.1875V3.55469H2.46094C1.10398 3.55469 0 4.65866 0 6.01562V24.1719C0 25.5288 1.10398 26.6328 2.46094 26.6328H25.5391C26.896 26.6328 28 25.5288 28 24.1719C28 23.6424 28 6.47637 28 6.01562C28 4.65866 26.896 3.55469 25.5391 3.55469ZM1.64062 6.01562C1.64062 5.5633 2.00862 5.19531 2.46094 5.19531H4.42969V6.5625C4.42969 7.01553 4.79697 7.38281 5.25 7.38281C5.70303 7.38281 6.07031 7.01553 6.07031 6.5625V5.19531H13.1797V6.5625C13.1797 7.01553 13.547 7.38281 14 7.38281C14.453 7.38281 14.8203 7.01553 14.8203 6.5625V5.19531H21.9297V6.5625C21.9297 7.01553 22.297 7.38281 22.75 7.38281C23.203 7.38281 23.5703 7.01553 23.5703 6.5625V5.19531H25.5391C25.9914 5.19531 26.3594 5.5633 26.3594 6.01562V9.24219H1.64062V6.01562ZM25.5391 24.9922H2.46094C2.00862 24.9922 1.64062 24.6242 1.64062 24.1719V10.8828H26.3594V24.1719C26.3594 24.6242 25.9914 24.9922 25.5391 24.9922Z"
        fill={color}
      />
    </svg>
  );
};
Calendar.propTypes = {
  color: PropTypes.string,
};

export default Calendar;
