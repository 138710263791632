import React from "react";
import PropTypes from "prop-types";

const Publication = ({ color = "#1D1D1F" }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <path
        d="M24.5 13.4167C24.504 14.9565 24.1442 16.4756 23.45 17.85C22.6268 19.4971 21.3614 20.8824 19.7954 21.8509C18.2293 22.8193 16.4246 23.3327 14.5833 23.3334C13.0435 23.3374 11.5245 22.9776 10.15 22.2834L3.5 24.5L5.71667 17.85C5.02242 16.4756 4.66265 14.9565 4.66667 13.4167C4.66738 11.5754 5.18071 9.77069 6.14917 8.20468C7.11763 6.63866 8.50296 5.3732 10.15 4.55003C11.5245 3.85579 13.0435 3.49602 14.5833 3.50003H15.1667C17.5984 3.63419 19.8952 4.66059 21.6173 6.3827C23.3394 8.10482 24.3658 10.4016 24.5 12.8334V13.4167Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Publication.propTypes = {
  color: PropTypes.string,
};
export default Publication;
