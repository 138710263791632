import React from "react";
import PropTypes from "prop-types";

const Membre = ({ color = "#1D1D1F" }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <path
        d="M18.2052 21.5075L17.5083 15.928C17.4096 15.1377 17.0255 14.4107 16.4283 13.8837C15.8312 13.3568 15.0621 13.0661 14.2656 13.0664H11.8657C11.0697 13.0666 10.3011 13.3575 9.70436 13.8845C9.10766 14.4114 8.7239 15.1381 8.62519 15.928L7.92721 21.5075C7.8889 21.814 7.91625 22.1252 8.00743 22.4203C8.09862 22.7155 8.25156 22.9879 8.45609 23.2194C8.66062 23.4509 8.91206 23.6363 9.19373 23.7631C9.47539 23.89 9.78082 23.9555 10.0897 23.9553H16.0438C16.3526 23.9554 16.6579 23.8897 16.9395 23.7628C17.221 23.6359 17.4723 23.4505 17.6767 23.219C17.8811 22.9875 18.034 22.7152 18.1251 22.4201C18.2162 22.125 18.2435 21.8139 18.2052 21.5075V21.5075Z"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0668 8.71107C14.8709 8.71107 16.3334 7.24853 16.3334 5.4444C16.3334 3.64027 14.8709 2.17773 13.0668 2.17773C11.2626 2.17773 9.80011 3.64027 9.80011 5.4444C9.80011 7.24853 11.2626 8.71107 13.0668 8.71107Z"
        stroke={color}
        strokeWidth="1.8"
      />
      <path
        d="M4.35563 11.9786C5.55839 11.9786 6.53341 11.0036 6.53341 9.80083C6.53341 8.59807 5.55839 7.62305 4.35563 7.62305C3.15288 7.62305 2.17786 8.59807 2.17786 9.80083C2.17786 11.0036 3.15288 11.9786 4.35563 11.9786Z"
        stroke={color}
        strokeWidth="1.8"
      />
      <path
        d="M21.7779 11.9786C22.9806 11.9786 23.9557 11.0036 23.9557 9.80083C23.9557 8.59807 22.9806 7.62305 21.7779 7.62305C20.5751 7.62305 19.6001 8.59807 19.6001 9.80083C19.6001 11.0036 20.5751 11.9786 21.7779 11.9786Z"
        stroke={color}
        strokeWidth="1.8"
      />
      <path
        d="M4.35566 15.2441H4.02246C3.50694 15.2441 3.00811 15.4269 2.61472 15.7601C2.22132 16.0932 1.95888 16.5552 1.87408 17.0637L1.51148 19.2415C1.45944 19.5535 1.476 19.8731 1.56001 20.178C1.64402 20.483 1.79345 20.766 1.99792 21.0074C2.20238 21.2487 2.45698 21.4427 2.74399 21.5756C3.031 21.7086 3.34353 21.7775 3.65986 21.7775H7.62232"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7778 15.2441H22.111C22.6266 15.2441 23.1254 15.4269 23.5188 15.7601C23.9122 16.0932 24.1746 16.5552 24.2594 17.0637L24.622 19.2415C24.674 19.5535 24.6575 19.8731 24.5735 20.178C24.4895 20.483 24.34 20.766 24.1356 21.0074C23.9311 21.2487 23.6765 21.4427 23.3895 21.5756C23.1025 21.7086 22.79 21.7775 22.4736 21.7775H18.5112"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
Membre.propTypes = {
  color: PropTypes.string,
};

export default Membre;
