import { useEffect, useState } from "react";
import { getBannerDetail } from "./BannerServiceApi";

export const useBannerDetail = (refreshBanner) => {
    const [detail, setDetail] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const loadDetail = async () => {
            setDataLoaded(false);
            setDetail("");
            const infoBanner = await getBannerDetail();
            setDetail(infoBanner);
            setDataLoaded(true);
        };
        loadDetail();
    }, [refreshBanner]);

    return [detail, dataLoaded];

};