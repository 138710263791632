const today = new Date();
let day = today.getDate();
let month = today.getMonth() + 1;
export const year = today.getFullYear();
if (month < 10) {
    month = '0' + month;
}
if (day < 10) {
    day = '0' + day;
}
export const currentDay = year + '-' + month + '-' + day;

export function getHours(timeFromApi) {
    if (timeFromApi.indexOf("h") === 1) {
        return "0" + timeFromApi.slice(0, 1);
    }
    return timeFromApi.slice(0, 2);
}

export function getMinutes(timeFromApi) {
    if (timeFromApi.slice(-1) === "h") {
        return "00";
    }
    return timeFromApi.slice(-2);
}

export function getTime(timeFromApi) {
    return getHours(timeFromApi) + ":" + getMinutes(timeFromApi);
}