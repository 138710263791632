import Style from './ReseauxSociaux.module.css';
import React from "react";
import Linkedin from "_common/component/icon/Linkedin";
import Facebook from "_common/component/icon/Facebook";
import Instagram from "_common/component/icon/Instagram";
import YoutubeIcon from "_common/component/icon/YoutubeIcon";
import useMedia from "_common/hook/useMedia";

const ReseauxSociaux = () => {
    const isMobile = useMedia(["(max-width: 1023px)", "(min-width: 1024px)"], [true, false], true);

    return (
        <ul className={Style.ReseauxSociauxList}>
            <li className={Style.ReseauxSociauxListItem}><a href={"https://www.linkedin.com/company/femmes-des-territoires"} target="_blank" aria-label="aller sur le Linkedin de Femmes des Territoires" rel="noopener noreferrer"><Linkedin color={isMobile ? "#FBFBFD" : "#1D1D1F"}/></a></li>
            <li className={Style.ReseauxSociauxListItem}><a href={"https://www.facebook.com/femmesdesterritoires"} target="_blank" aria-label="aller sur le Facebook de Femmes des Territoires" rel="noopener noreferrer"><Facebook color={isMobile ? "#FBFBFD" : "#1D1D1F"}/></a></li>
            <li className={Style.ReseauxSociauxListItem}><a href={"https://www.instagram.com/femmesdesterritoires/"} target="_blank" aria-label="aller sur le Instagram de Femmes des Territoires" rel="noopener noreferrer"><Instagram color={isMobile ? "#FBFBFD" : "#1D1D1F"}/></a></li>
            <li><a href={"https://www.youtube.com/@femmesdesterritoires9303"} target="_blank" aria-label="aller sur le Youtube de Femmes des Territoires" rel="noopener noreferrer"><YoutubeIcon color={isMobile ? "#FBFBFD" : "#1D1D1F"}/></a></li>
        </ul>
    )
}

export default ReseauxSociaux;
