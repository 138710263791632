import {post} from "_common/service/ApiService";

let routeSendResetPassword = process.env.REACT_APP_API_URL + "/api/reset_password";

export const sendResetPassword = async (email) => {
    return  post(routeSendResetPassword, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            email
        })
    });
}
let routeResetPassword = process.env.REACT_APP_API_URL + "/api/reset_password/new_password";

export const resetPassword = async (resetToken, motDePasse) => {
    return  post(routeResetPassword, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            resetToken,
            motDePasse
        })
    });
}