import React from "react";
import PropTypes from "prop-types";

const FooterDroite = ({ width = 347, height = 103 }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 347 103" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img">
      <path
        d="M315.5 47C273 105.5 237.5 100.5 168 86C222.835 59.7327 256.105 50.9243 315.5 47Z"
        fill="url(#paint0_linear_4906:7844)"
      />
      <path
        d="M347.5 103V0C304.272 69.2156 280.252 93.8117 238 95.5C180.329 93.5285 137 74.5 95.5 78C54 81.5 0 103 0 103H347.5Z"
        fill="url(#paint1_linear_4906:7844)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4906:7844"
          x1="315.986"
          y1="47"
          x2="-6.75368"
          y2="81.1755"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00133931" stopColor="#FFC412" />
          <stop offset="0.75089" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4906:7844"
          x1="338.5"
          y1="110"
          x2="0.499999"
          y2="103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00AAA5" />
          <stop offset="0.670435" stopColor="#00AAA5" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
FooterDroite.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FooterDroite;
