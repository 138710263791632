import React from "react";
import PropTypes from "prop-types";

const ChevronLeft = ({ color, size = 25 }) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="éléments précédents">
    <path
      d="m 15.581966,18.9891 -5.9999702,-6 5.9999702,-5.99996"
      stroke={color}
      strokeWidth="1.65"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ChevronLeft.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default ChevronLeft;
