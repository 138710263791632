import { useState, useCallback, useRef } from "react";

/**
 *
 * Force Component redraw
 */
export const useRefresh = () /*: [() => void, number]*/ => {
  const prevCountRef = useRef(0);
  const countRef = useRef(0);
  const [count, setCount] = useState(countRef.current);
  const refresh = useCallback(() => {
    prevCountRef.current = countRef.current;
    countRef.current++;
    setCount(countRef.current);
  }, []);

  return [refresh, count, prevCountRef];
};

export default useRefresh;
