import { changeRefreshToken, isTokenExpired } from "_core/authentification/service/AuthService";
import { storageRead, storageWrite } from "./StorageService";

let _token;
let handle400PlusError = () => { };
export const set400PlusErrorHandler = (handler) => {
    handle400PlusError = handler;
};

let refreshToken;

export const setBearerToken = (bearer) => {
    _token = bearer;
}
export const setToken = (token) => {
    storageWrite("token", token);
    setBearerToken(token)
}

const addAuthorizationHeader = (options) => {
    const headers = options.headers || {}

    if (headers && !headers.Authorization) {
        if (_token) {
            headers.Authorization = 'Bearer ' + _token
        } else {
            _token = storageRead("token");
            if (_token) {
                headers.Authorization = 'Bearer ' + _token
            }
        }
    }
    options.headers = headers;
}

const cache = new Map();
const buffer = new Map();
const CACHE_VALIDITY_DURATION_MS = 1000;

export const clearRequestCache = () => {
    cache.clear();
    buffer.clear();
};
export const get = async (url, options = {}, withHandle400 = true) => {
    const { withCache, ...opts } = options;
    let hash;

    if (withCache) {
        hash = url + JSON.stringify(opts);

        if (cache.has(hash)) {
            const data = cache.get(hash);
            if (new Date().getTime() - CACHE_VALIDITY_DURATION_MS < data.date) {
                return data.response;
            }
            clearRequestCache();
        }
        if (buffer.has(hash)) {
            return await buffer.get(hash);
        }
    }

    refreshToken = storageRead("refreshToken");

    if (withHandle400 && _token && isTokenExpired(_token) && refreshToken) {
        await changeRefreshToken(refreshToken, 401);
    }

    const resp = new Promise((resolve, reject) => {
        addAuthorizationHeader(opts);
        if (!opts.method) {
            opts.method = "GET";
        }
        fetch(url, opts)
            .then((resp) => {
                if (resp.status >= 400) {
                    handle400PlusError(withHandle400 && refreshToken, resp.status);
                    throw new Error(resp.status);
                }
                return resp.json();
            })
            .then((data) => {
                if (withCache) {
                    cache.set(hash, { response: data, date: new Date().getTime() });
                    buffer.delete(hash);
                }
                resolve(data);
            })
            .catch((err) => {
                if (withCache) {
                    buffer.delete(hash);
                }
                console.error("GET", err);
                reject(err);
            });
    });
    if (withCache) {
        buffer.set(hash, resp);
    }
    return resp;
};

export const post = async (url, options = {}, withHandle400 = true) => {
    refreshToken = storageRead("refreshToken");

    if (withHandle400 && _token && isTokenExpired(_token) && refreshToken) {
        await changeRefreshToken(refreshToken, 401);
    }

    return new Promise((resolve, reject) => {
        addAuthorizationHeader(options);
        if (!options.method) {
            options.method = "POST";
        }
        fetch(url, options)
            .then(async(resp) => {
                if (resp.status >= 400) {
                    handle400PlusError(withHandle400 && refreshToken, resp.status)
                    if(resp.status === 422) {
                        return resp.text().then(text => {
                            throw new Error(resp.status + ' ' + text);
                        })
                    } else {
                        throw new Error(resp.status);
                    }
                }
                if (resp.status === 204) {
                    return resolve();
                }
                return resp.json();
            })
            .then(resolve)
            .catch((err) => {
                console.error("POST", err);
                reject(err);
            });
    });
};

export const del = async (url, options = {}, withHandle400 = true) => {
    refreshToken = storageRead("refreshToken");

    if (withHandle400 && _token && isTokenExpired(_token) && refreshToken) {
        await changeRefreshToken(refreshToken, 401);
    }

    return new Promise((resolve, reject) => {
        addAuthorizationHeader(options);
        if (!options.method) {
            options.method = "DELETE";
        }
        fetch(url, options)
            .then((resp) => {
                if (resp.status >= 400) {
                    handle400PlusError(withHandle400 && refreshToken, resp.status)
                    throw new Error(resp.status);
                }
                if (resp.status === 204) {
                    return resolve();
                }
                return resp.json();
            })
            .then(resolve)
            .catch((err) => {
                console.error("DELETE", err);
                reject(err);
            });
    });
};
export const put = async (url, options = {}, withHandle400 = true) => {
    refreshToken = storageRead("refreshToken");

    if (withHandle400 && _token && isTokenExpired(_token) && refreshToken) {
        await changeRefreshToken(refreshToken, 401);
    }

    return new Promise((resolve, reject) => {
        addAuthorizationHeader(options);
        if (!options.method) {
            options.method = "PUT";
        }
        if (!options.headers) {
            options.headers = { 'Content-Type': 'application/json' }
        } else if (!options.headers['Content-Type']) {
            options.headers['Content-Type'] = 'application/json'
        }
        fetch(url, options)
            .then(async(resp) => {
                if (resp.status >= 400) {
                    handle400PlusError(withHandle400 && refreshToken, resp.status)
                    if(resp.status === 422) {
                        return resp.text().then(text => {
                            throw new Error(resp.status + ' ' + text);
                        })
                    } else {
                        throw new Error(resp.status);
                    }
                }
                return resp.json();
            })
            .then(resolve)
            .catch((err) => {
                console.error("PUT", err);
                reject(err);
            });
    });
};

export const postExport = async (url, options = {}, withHandle400 = true) => {
    refreshToken = storageRead("refreshToken");

    if (withHandle400 && _token && isTokenExpired(_token) && refreshToken) {
        await changeRefreshToken(refreshToken, 401);
    }

    return new Promise((resolve, reject) => {
        addAuthorizationHeader(options);
        if (!options.method) {
            options.method = "POST";
        }
        fetch(url, options)
            .then((resp) => {
                if (resp.status >= 400) {
                    handle400PlusError(withHandle400 && refreshToken, resp.status)
                    throw new Error(resp.status);
                }
                return resp.blob()
            }).then(resolve)
            .catch((err) => {
                console.error("POST", err);
                reject(err);
            });
    });
};
