import React, { useState, useEffect } from "react";
import ToasterService from "./NotificationService";
import Toaster from "./Toaster";
import Style from "./ToasterProvider.module.css";

const ToasterProvider = () => {
  const [activeNotifications, setActiveNotifications] = useState([]);
  const handleClose = (conf) => {
    setActiveNotifications((prevActiveNotifications) => {
      const index = prevActiveNotifications.indexOf(conf);
      prevActiveNotifications.splice(index, 1);
      return [...prevActiveNotifications];
    });
  };
  useEffect(() => {
    ToasterService.setNotificationProvider((conf) => {
      setActiveNotifications((prevActiveNotifications) => [
        ...prevActiveNotifications,
        conf,
      ]);
      return () => handleClose(conf);
    });
  }, []);

  const notifications = activeNotifications.map((conf) => {
    return (
      <Toaster
        key={conf.uid}
        onClose={() => handleClose(conf)}
        typeNotification={conf.typeNotification}
        duration={conf.duration}
      >
        {conf.message}
      </Toaster>
    );
  });

  return <div className={Style.Provider}>{notifications}</div>;
};

export default ToasterProvider;
