import React from "react";
import PropTypes from "prop-types";
import Style from "./AppLeftMenu.module.css";
import clsx from "clsx";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { routesWithMenu, ROUTE_HOME } from "_core/router/routes";
import { hasRole } from "_core/authentification/service/AuthService";

const renderIconAsComponent = (menu, isActive) => {
  const Component = menu?.icon.value;
  return isActive ? <Component color="#2c827f" /> : <Component />;
};

const renderIconAsImage = (menu) => {
  if (menu?.label) {
    return <img src={menu?.icon.value} alt="" aria-hidden="true" />;
  }
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={menu?.icon.value} alt="" aria-hidden="true" />;
};

const renderIcon = (menu, isActive) => {
  return menu?.icon.type === "svg" ? renderIconAsImage(menu) : renderIconAsComponent(menu, isActive);
};

const AppLeftMenu = ({ user }) => {
  const location = useLocation();
  return (
    <nav id="navigation-principale" aria-label="navigation principale" className={Style.Navigation}>
      <ul>
        {routesWithMenu.map(({ path, key, menu, exact, roles }) => {
          const isActive = !!matchPath(location.pathname, {
            path: path,
            exact: exact || path === ROUTE_HOME,
            strict: false,
          });

          const hasPermission = hasRole(roles, user);
          if (!hasPermission) {
            return null;
          }

          return (
            <li key={key} data-key="menu-item">
              <NavLink
                to={path}
                exact={true}
                activeStyle={{
                  color: "#2c827f",
                }}
                className={clsx(Style.MenuItem, isActive ? Style.MenuItemSelected : null)}
              >
                {renderIcon(menu, isActive)}
                <span className={Style.MenuItemLabel}>{menu?.label}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

AppLeftMenu.propTypes = {
  user: PropTypes.object,
}

export default AppLeftMenu;
