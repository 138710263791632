import React from "react";
import PropTypes from "prop-types";

const ChevronRight = ({ color = "white", size = 25 }) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="éléments suivants" >
    <path
      d="M9.58203 18.9891L15.582 12.9891L9.58203 6.98914"
      stroke={color}
      strokeWidth="1.65"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
ChevronRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
export default ChevronRight;
